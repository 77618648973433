<template>
  <div class="contact-widget">
    <b-button
      variant="link"
      pill
      class="handler border"
      :class="{ 'no-animate': isOpened }"
      @click="loadUserData()"
      v-b-tooltip.hover
      :title="$t('help.button.message')"
    >
      <QuestionMarkCircleIcon size="3x" />
    </b-button>

    <validation-observer
      ref="contactRules"
      slim
      #default="{ invalid }"
      class=""
    >
      <b-modal
        id="contact-widget-modal"
        @hide="clearUserData"
        size="lg"
        v-model="isOpened"
        centered
        scrollable
      >
        <template #modal-title>
          {{ $t("help.modal.title") }}
        </template>
        <template #modal-footer="{ close }">
          <b-button
            variant="primary"
            pill
            @click="submit()"
            :disabled="isLoading || invalid"
          >
            <b-spinner small v-if="isLoading">
              {{ $t("common.loading") }}
            </b-spinner>
            {{ $t("common.send") }}
          </b-button>
          <b-button variant="secondary" pill @click="close()">
            {{ $t("common.cancel") }}
          </b-button>
        </template>
        <template #default>
          <b-form>
            <!-- @todo Adicionar nome, e email, caso não autenticado -->
            <validation-provider
              #default="{ errors, required }"
              :rules="{ required: true, 'full-name': true }"
              :name="$t('curriculum.personal.name.label')"
              slim
            >
              <b-form-group
                :state="errors.length == 0"
                :invalid-feedback="errors.join(', ')"
              >
                <template #label>
                  <label for="name" class="d-block">
                    {{ $t("curriculum.personal.name.label") }}
                    <span v-if="required" class="text-danger">*</span>
                  </label>
                </template>
                <b-form-input
                  id="name"
                  v-model="name"
                  :placeholder="$t('curriculum.personal.name.label')"
                  :disabled="$auth.check()"
                  :class="{ 'is-invalid': errors.length > 0 }"
                />
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="{ errors, required }"
              :rules="{ required: true, email: true }"
              :name="$t('curriculum.personal.primary_email.label')"
              slim
            >
              <b-form-group
                :state="errors.length == 0"
                :invalid-feedback="errors.join(', ')"
              >
                <template #label>
                  <label for="email" class="d-block">
                    {{ $t("curriculum.personal.primary_email.label") }}
                    <span v-if="required" class="text-danger">*</span>
                  </label>
                </template>

                <b-form-input
                  id="email"
                  v-model="email"
                  :placeholder="$t('curriculum.personal.primary_email.label')"
                  :disabled="$auth.check()"
                  :class="{ 'is-invalid': errors.length > 0 }"
                />
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="{ errors, required }"
              :rules="{ required: true }"
              :name="$t('help.message.name')"
              slim
            >
              <b-form-group
                :state="errors.length == 0"
                :invalid-feedback="errors.join(', ')"
                :description="$t('help.message.description')"
              >
                <template #label>
                  <label for="message" class="d-block">
                    {{ $t("help.message.label") }}
                    <span v-if="required" class="text-danger">*</span>
                  </label>
                </template>

                <b-form-textarea
                  id="message"
                  v-model="text"
                  :placeholder="$t('help.message.label')"
                  rows="3"
                  max-rows="6"
                  :class="{ 'is-invalid': errors.length > 0 }"
                />
              </b-form-group>
            </validation-provider>
          </b-form>
        </template>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import companyMixin from "@state/company";
import { useTheme } from "@state/system/theme";
import { QuestionMarkCircleIcon } from "@vue-hero-icons/solid";
import { useCommunication } from "@state/system/communication";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  mixins: [companyMixin],
  components: {
    ValidationObserver,
    ValidationProvider,
    QuestionMarkCircleIcon,
  },
  data() {
    return {
      name: "",
      email: "",
      text: "",
      isOpened: false,
      isLoading: false,
    };
  },
  setup() {
    const theme = useTheme();
    const communication = useCommunication();

    return {
      communication,
      isMobile: theme.isMobile,
    };
  },
  computed: {
    isValidated() {
      return this.$refs.contactRules.validate({ silent: true });
    },
  },
  methods: {
    loadUserData() {

      /**
       * When in SJ redirect user to faq page
       */
      if (this.company.config.sj) {
        this.$router.push({ name: "faq" });
        return;
      }

      this.isOpened = true;

      if (this.$auth.check()) {
        this.name = this.$auth.user().name;
        this.email = this.$auth.user().email;
      }
    },
    clearUserData() {
      this.name = "";
      this.email = "";
      this.text = "";
    },
    submit() {
      this.isLoading = true;
      const currentCursor = document.body.style.cursor;
      document.body.style.cursor = "progress";
      return new Promise((resolve, reject) => {
        this.$refs.contactRules.validate().then((success) => {
          if (success) {
            // GET THE STATE AND PUSH TO SERVER
            this.communication
              .askForHelp({
                name: this.name,
                email: this.email,
                text: this.text,
              })
              .then((data) => {
                if (data.success) {
                  if (this.isMobile() || data.need_mail_confirm) {
                    this.$bvModal.hide("contact-widget-modal");
                  } else {
                    this.isOpened = false;
                  }

                  resolve(data.success);
                } else {
                  reject(false);
                }
              })
              .catch((err) => {
                //
              })
              .finally(() => {
                this.isLoading = false;
                document.body.style.cursor = currentCursor;
              });
          } else {
            //this.scrollToError();
          }
        });
      });
      return false;
    },
  },
};
</script>