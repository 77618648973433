<template>
  <div class="container my-5">
    <div class="row justify-content-center">
      <div class="col-md-12 col-lg-8">
        <h1 class="text-center mb-3">{{ $t("faq.title") }}</h1>
        <div class="accordion" id="accordion-faq">
          <div class="accordion-item my-3" v-for="(item, index) in items" :key="index">
            <h2 class="accordion-header">
              <button class="accordion-button" type="button" aria-expanded="true" aria-controls="collapseOne" v-b-toggle="`accordion-${index}`">
                {{ item.question }}
              </button>
            </h2>
            <b-collapse :id="`accordion-${index}`" role="tabpanel">
              <div class="accordion-body" v-html="item.answer"></div>
            </b-collapse>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import companyMixin from "@state/company";
export default {
    mixins: [companyMixin],
    computed: {
      items() {
        const context = this.company.config.sj ? 'faq.sj.questions' : 'faq.portal.questions'
        return this.$t(context)
      }
    }
};
</script>

<style lang="scss" scoped>

.accordion-item {
    border-radius: 0 !important;
}

.accordion-item:not(:first-of-type) {
    border-top: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.accordion-button {
    border-radius: 0 !important;
}

</style>