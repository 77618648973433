<template>
  <!-- @TODO separar cada tipo de card em seu próprio subcomponente -->
  <div
    class="vacancy-card card cursor-pointer"
    :class="{ 'border-right-0 border-top-0': stacked, 'rounded-0': !rounded }"
    v-on="$listeners"
  >
    <!-- <div v-if="!noImage">
            <img
                class="card-img-top"
                :src="require(`@assets/images/vacancies/${item.image_url}.jpg`)"
                alt=""
            />
        </div> -->

    <vacancy-banner
      :item="item"
      v-if="!noImage"
      aspect="3:1"
      :rounded="rounded"
    />

    <div :class="{ 'p-4': featured, 'card-body': !list }">
      <div v-if="featured" class="d-flex flex-column gap-0">
        <div class="d-flex justify-content-between">
          <div
            class="title text-md font-weight-semibold text-truncate w-75"
            :title="item.occupation"
            :id="generateId('vacancy-card-occupation')"
          >
            {{ item.occupation }}
          </div>
          <b-tooltip
            v-if="showTooltip(generateId('vacancy-card-occupation'))"
            :target="generateId('vacancy-card-occupation')"
            triggers="hover"
          >
            {{ item.occupation }}
          </b-tooltip>
          <div>
            <fa-icon
              v-if="item.is_special_needs_job || (item.info_special_needs != null && item.info_special_needs != '')"
              icon="fa-solid fa-wheelchair"
              class="text-primary text-md"
            />
          </div>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <div
            class="subtitle text-sm text-truncate w-75"
            :id="generateId('vacancy-card-company')"
          >
            {{ getCompany(item) }}
          </div>
          <b-tooltip
            v-if="showTooltip(generateId('vacancy-card-company'))"
            :target="generateId('vacancy-card-company')"
            triggers="hover"
          >
            {{ getCompany(item, true) }}
          </b-tooltip>
          <div
            v-if="item.workplace_type && item.workplace_type !== 'Presencial'"
          >
            <vacancy-tags :item="item" :size="1" :type="'workplace_type'" />
          </div>
        </div>
        <div
          class="attrs d-flex justify-content-between align-items-center text-sm"
        >
          <div v-if="!noLocation && getLocalization">
            <span>
              <b-icon icon="geo-alt-fill" />
              {{ getLocalization }}
            </span>
          </div>
          <div>
            <span v-if="item.published_hours || item.published_days">
              <b-icon icon="clock" />
                {{ $tc("vacancy.published_at") }}
              <span v-if="item.published_hours < 48">
                {{ $tc("vacancy.published_hours", item.published_hours) }}
              </span>
              <span v-else>
                {{ $tc("vacancy.published_days", item.published_days) }}
              </span>
            </span>
          </div>
        </div>
      </div>
      <div v-else-if="stacked" class="container">
        <div class="row justify-content-between">
          <div
            v-if="company.config.sj || company.config.is_agreggator"
            class="col justify-content-start"
          >
            <vacancy-avatar :item="item" />
          </div>

          <div class="col-8 col-lg-9 justify-content-start">
            <div class="d-flex justify-content-between">
              <span
                class="title text-lg m-0 text-truncate"
                :id="generateId('vacancy-card-occupation')"
              >
                {{ getOccupation(item.occupation) }}
              </span>
              <b-tooltip
                v-if="showTooltip(generateId('vacancy-card-occupation'))"
                :target="generateId('vacancy-card-occupation')"
                triggers="hover"
              >
                {{ getOccupation(item.occupation, true) }}
              </b-tooltip>
            </div>
            <div class="d-flex justify-content-between">
              <span
                class="subtitle text-sm m-0 text-truncate w-75"
                :id="generateId('vacancy-card-company')"
              >
                {{ getCompany(item) }}
              </span>
              <b-tooltip
                v-if="showTooltip(generateId('vacancy-card-company'))"
                :target="generateId('vacancy-card-company')"
                triggers="hover"
              >
                {{ getCompany(item, true) }}
              </b-tooltip>
            </div>

            <div class="attrs d-flex justify-content-start align-items-center">
              <div v-if="!noLocation && getLocalization">
                <span>
                  <b-icon icon="geo-alt-fill" />
                  {{ getLocalization }}
                </span>
              </div>
              <div
                v-if="
                  item.workplace_type && item.workplace_type !== 'Presencial'
                "
              >
                <vacancy-tags
                  :item="item"
                  :size="1"
                  :type="'workplace_type'"
                  class="ml-2"
                />
              </div>
            </div>
            <div class="attrs mt-4" v-if="showStatus">
              <b-badge
                :variant="
                  getStatusVariant(
                    application.candidate_status,
                    application.status
                  )
                "
              >
                {{
                  $t(
                    getStatus(
                      application.candidate_status,
                      application.status,
                      application.grade
                    )
                  )
                }}
              </b-badge>
            </div>
          </div>
          <div class="col row justify-content-end">
            <fa-icon
              v-if="item.is_special_needs_job"
              icon="fa-solid fa-wheelchair"
              class="text-primary text-lg"
            />

            <div v-if="!company.config.is_agreggator">
              <div
                v-if="!noBookmark && !bookmarked"
                :id="generateId('save-vacancy')"
              >
                <BookmarkIcon class="text-primary" @click="bookmark(item.id)" />

                <b-tooltip :target="generateId('save-vacancy')">{{
                  $auth.check()
                    ? $t("vacancy.actions.save_vacancy")
                    : $t("vacancy.actions.log_to_save")
                }}</b-tooltip>
              </div>
              <div v-else-if="!noBookmark" :id="generateId('remove-vacancy')">
                <BookmarkIcon
                  class="text-primary"
                  fill="currentColor"
                  @click="bookmark(item.id)"
                />

                <b-tooltip :target="generateId('remove-vacancy')">{{
                  $t("vacancy.actions.remove_favorite")
                }}</b-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else-if="list"
        class="d-flex gap-0 justify-content-between align-items-center mx-2 my-2"
      >
        <div class="d-flex flex-column gap-0">
          <div class="d-flex justify-content-between">
            <div class="title font-weight-semibold">
              {{ item.occupation }}
              <fa-icon
                v-if="item.is_special_needs_job"
                icon="fa-solid fa-wheelchair"
                class="text-primary"
              />
            </div>
          </div>
        </div>
        <div class="text-left d-flex flex-row gap-2 text-sm">
          <span
            v-if="!noLocation && getLocalization"
            class="d-none d-md-inline"
          >
            <b-icon icon="geo-alt-fill" size="1x" />
            {{ getLocalization }}
          </span>
          <span v-if="item.published_days && item.published_days > 0">
            <ClockIcon size="1x" />
            <span class="d-none d-md-inline">{{
              $t("vacancy.published_at")
            }}</span>
            <span v-if="!isMobile()">{{
              $tc("vacancy.published_days", item.published_days)
            }}</span>
            <span v-else>{{ item.published_days }}</span>
          </span>
          <span v-if="item.total_vacancies">
            <UsersIcon size="1x" />
            {{ item.total_vacancies }}
          </span>
        </div>
      </div>

      <!-- @todo Review these card types -->

      <div v-else-if="slim" class="d-flex gap-sm">
        <div v-if="company.config.sj">
          <vacancy-avatar :item="item" />
        </div>

        <span
          class="card-subtitle text-sm text-truncate"
          :id="generateId('vacancy-card-company')"
        >
          {{ getCompany(item) }}
        </span>
        <b-tooltip
          v-if="showTooltip(generateId('vacancy-card-company'))"
          :target="generateId('vacancy-card-company')"
          triggers="hover"
        >
          {{ getCompany(item, true) }}
        </b-tooltip>

        <h5 class="card-title text-uppercase">
          {{ item.occupation }}
          <fa-icon
            v-if="item.is_special_needs_job"
            icon="fa-solid fa-wheelchair"
            class="text-primary"
          />
        </h5>
        <p class="card-text text-muted">
          <span
            class="mr-2"
            v-if="item.published_days && item.published_days > 0"
          >
            <b-icon icon="clock" /> Publicada há {{ getPublicationDays }}
          </span>

          <span class="mr-2" v-if="item.total_vacancies">
            <b-icon icon="briefcase-fill" />
            {{ item.total_vacancies }} vaga(as)
          </span>

          <span v-if="!noLocation && getLocalization">
            <b-icon icon="geo-alt-fill" />
            {{ getLocalization }}
          </span>
        </p>
        <vacancy-tags :item="item" :size="4" />
      </div>

      <div v-else class="mb-3">
        <span class="text-sm">{{ item.company_name }}</span>
        <h5 class="card-title mb-2">
          {{ item.occupation }}
          <fa-icon
            v-if="item.is_special_needs_job"
            icon="fa-solid fa-wheelchair"
            class="text-primary"
          />
        </h5>
        <p class="card-text text-muted">
          <span
            class="mr-2"
            v-if="item.published_days && item.published_days > 0"
          >
            <b-icon icon="clock" /> Publicada há {{ getPublicationDays }}
          </span>

          <span class="mr-2" v-if="item.total_vacancies">
            <b-icon icon="briefcase-fill" />
            {{ item.total_vacancies }} vaga(as)
          </span>
          <span v-if="!noLocation && getLocalization">
            <b-icon icon="geo-alt-fill" />
            {{ getLocalization }}
          </span>
        </p>
        <vacancy-tags :item="item" :size="4" />
      </div>
    </div>

    <!-- <div
            class="actions card-footer btn-group"
            v-if="application_id"
        >
            <b-button
                pill
                variant="outline-primary"
                @click="openVacancy(application_id)"
            >
                <span class="d-none d-xl-inline">{{ $t("common.see") }}</span>
                {{ $t("common.description") }}
            </b-button>
            <b-button
                pill
                @click="openApplication(application_id)"
                variant="primary"
            >
                <span class="d-none d-xl-inline">{{ $t("common.see") }}</span>
                {{ $t("common.progress") }}
            </b-button>
        </div> -->
  </div>
</template>

<script>
import * as _ from "lodash-es";
import companyMixin from "@state/company";

import { useVacancies } from "@state/vacancy/vacancies";
import { useTheme } from "@state/system/theme";

import vacanciesMixin from "@mixins/vacancies";

import VacancyTags from "./VacancyTags.vue";
import VacancyBanner from "./VacancyBanner.vue";
import VacancyAvatar from "./VacancyAvatar.vue";

import { OfficeBuildingIcon } from "@vue-hero-icons/solid";
import { ClockIcon, UsersIcon } from "@vue-hero-icons/outline";
import { BookmarkIcon } from "@components/icons";

export default {
  mixins: [companyMixin, vacanciesMixin],
  components: {
    VacancyBanner,
    VacancyTags,
    VacancyAvatar,
    OfficeBuildingIcon,
    BookmarkIcon,
    ClockIcon,
    UsersIcon,
  },
  setup() {
    const vacancies = useVacancies();
    const theme = useTheme();

    return {
      vacancies,
      isMobile: theme.isMobile,
    };
  },
  props: {
    item: {
      required: true,
      type: Object,
    },
    application: {
      required: false,
      type: Object,
    },
    type: {
      type: String,
      required: true,
      //default: 'stacked',
      validator: (prop) =>
        ["slim", "stacked", "featured", "list"].indexOf(prop) >= 0,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    // bookmarked: {
    //   type: Boolean,
    //   default: false,
    // },
    noBookmark: {
      type: Boolean,
      default: false,
    },
    noLocation: {
      type: Boolean,
      default: false,
    },
    showStatus: {
      type: Boolean,
      default: false,
    },

    noFooter: {
      type: Boolean,
      default: false,
    },
    noTags: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    bookmarked() {
      return _.some(this.vacancies.favorites, { id: this.item.id });
    },
    slim() {
      return this.type == "slim";
    },
    stacked() {
      return this.type == "stacked";
    },
    featured() {
      return this.type == "featured";
    },
    list() {
      return this.type == "list";
    },
    noImage() {
      return this.stacked || this.slim || !this.featured;
    },
    getLocalization() {
      var localization = "";

      if (this.item.city) {
        localization +=
          this.item.city +
          (this.item.workplace_complement || this.item.state ? " - " : "");
      }

      if (this.item.workplace_complement) {
        localization +=
          this.item.workplace_complement + (this.item.state ? " - " : "");
      }

      if (this.item.state) {
        localization += this.item.state + (this.item.country ? " - " : "");
      }

      if (this.item.country) {
        localization += this.item.country;
      }

      return localization.length > 0 ? localization : false;
    },
    getPublicationDays() {
      return this.$tc("vacancy.published_days", this.item.published_days);
    },
  },
  methods: {
    getOccupation(occupation, tooltip = false) {
      return occupation;
    },
    getCompany(item, tooltip = false) {
      let company = item.company_name
        ? item.company_name
        : item.publisher_company
        ? item.publisher_company
        : "";

      return company;
    },
    showTooltip(string) {
      const elemento = document.getElementById(string);

      // console.log(elemento);
      return elemento && elemento.clientWidth < elemento.scrollWidth;
    },
    openVacancy(id) {
      this.$router.push({
        name: "vacancy-show",
        params: { vacancy_id: id },
      });
    },

    openApplication(id) {
      this.$router.push({
        name: "application-progress",
        params: { application_id: id },
      });
    },
    generateId(prefix) {
      return prefix + this._uid;
    },
    bookmark(id) {
      this.vacancies
        .favorite_vacancy(id)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
