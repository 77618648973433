import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

import * as _ from "lodash-es";

//import matomo from "./plugins/matomo";

//services
//import ConfigService from '@services/config/Config'

import { useTheme } from "@state/system/theme";

const router = new Router({
    mode: "history",
    //base: process.env.BASE_URL,
    scrollBehavior(to, from, savedPosition) {
        if (document.getElementById("page-component")) {
            document.getElementById("page-component").scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        }
        // always scroll 10px above the element #main
        return {
            // could also be
            // el: document.getElementById('main'),
            selector: "#page-content",
            x: 0,
            y: 0,
            behavior: "smooth",
        };
    },
    routes: [
        {
            // =============================================================================
            // ROTAS DO PORTAL (Públicas)
            // =============================================================================
            path: "",
            component: () => import("./layouts/painel/Painel.vue"),
            children: [
                {
                    path: "/go/:module/:hash/:auth_type?",
                    component: () => import("@/views/home/Redirecting.vue"),
                    name: "token",
                    meta: {
                        sidebar: false,
                    },
                },
                {
                    path: "/enroll-redirecting",
                    component: () =>
                        import("@/views/home/EnrollRedirecting.vue"),
                    name: "enroll-redirecting",
                    meta: {
                        sidebar: false,
                    },
                    props: (route) => ({
                        enrollUrl: route.params.enroll_url,
                        publisherCompany: route.params.publisher_company,
                    }),
                },
                {
                    path: "/confirm-communication/:hash",
                    component: () =>
                        import("@/views/communication/Confirm.vue"),
                    name: "confirm-communication",
                    meta: {
                        sidebar: false,
                    },
                },
                {
                    path: "/reset-password/:auth_id/:token",
                    component: () => import("@/views/user/ResetPassword.vue"),
                    name: "reset-password",
                    meta: {
                        sidebar: false,
                    },
                },
                {
                    path: "/",
                    name: "home",
                    component: () => import("@/views/home/Main.vue"),
                    meta: {
                        sidebar: false,
                    },
                },
                {
                    path: "/customize",
                    name: "customize",
                    component: () => import("@/views/home/Main.vue"),
                    meta: {
                        sidebar: false,
                        section: "home",
                        auth: {
                            roles: ["selecty"],
                            redirect: { name: "login" },
                        },
                    },
                },

                {
                    path: "/search",
                    name: "vacancy-search",
                    component: () => import("@/views/vacancy/List.vue"),
                    meta: {
                        sidebar: false,
                    },
                },
                {
                    path: "/vaga/:vacancy_id/:slug?",
                    name: "vacancy-show",
                    component: () => import("@/views/vacancy/Show.vue"),
                    meta: {
                        sidebar: false,
                    },
                    props: (route) => ({
                        vacancyId: route.params.vacancy_id,
                    }),
                },
                {
                    path: "/login/:provider?",
                    name: "login",
                    component: () => import("@/views/user/Login.vue"),
                    meta: {
                        sidebar: false,
                    },
                    props: (route) => ({
                        ...route.params,
                        provider: route.params.provider,
                    }),
                },
                {
                    path: "/login-teste",
                    name: "login-teste",
                    component: () => import("@/views/user/Login.vue"),
                    meta: {
                        sidebar: false,
                    },
                    props: (route) => route.params,
                },

                // {
                //     path: "/social-media-authentication/:social_media/:hash",
                //     name: "social-media-authentication",
                //     component: () =>
                //         import("@/views/user/new-account/SocialMedia.vue"),
                //     meta: {
                //         sidebar: false,
                //     },
                //     props: (route) => ({
                //         social_media: route.params.social_media,
                //         hash: route.params.hash,
                //     }),
                // },
                {
                    path: "/create-account",
                    name: "create-account",
                    component: () =>
                        import("@/views/user/new-account/Step1.vue"),
                    meta: {
                        sidebar: false,
                    },
                },
                {
                    path: "/new-account/step-1/:vacancy_id?",
                    name: "register1",
                    component: () =>
                        import("@/views/user/new-account/Step1.vue"),
                    meta: {
                        sidebar: false,
                        redirect: "/",
                    },
                    props: (route) => ({
                        vacancy_id: route.params.vacancy_id,
                        account_exists: route.params.account_exists,
                    }),
                },
                {
                    path: "/new-account/step-2/:vacancy_id?",
                    name: "register2",
                    component: () =>
                        import("@/views/user/new-account/Step2.vue"),
                    meta: {
                        sidebar: false,
                        redirect: "/",
                    },
                    props: (route) => ({
                        vacancy_id: route.params.vacancy_id,
                        create_curriculum: route.params.create_curriculum,
                    }),
                },
                {
                    path: "/new-account/step-3/:vacancy_id?",
                    name: "register3",
                    component: () =>
                        import("@/views/user/new-account/Step3.vue"),
                    meta: {
                        sidebar: false,
                        redirect: "/",
                    },
                    props: (route) => ({
                        vacancy_id: route.params.vacancy_id
                    }),
                },
                {
                    path: "/new-account/step-4/:vacancy_id?",
                    name: "register4",
                    component: () =>
                        import("@/views/user/new-account/Step4.vue"),
                    meta: {
                        sidebar: false,
                        redirect: "/",
                    },
                    props: (route) => ({
                        vacancy_id: route.params.vacancy_id,
                        change_password: route.params.change_password,
                    }),
                },

                {
                    path: "/complete-registration",
                    name: "complete-registration",
                    component: () =>
                        import(
                            "@/views/user/new-account/CompleteRegistration.vue"
                        ),
                    meta: {
                        auth: {
                            roles: ["candidate", "viewer", "admin"],
                            redirect: { name: "login" },
                        },
                        sidebar: false,
                    },
                },
                {
                    path: "/forgot-password",
                    name: "forgot-password",
                    component: () =>
                        import("@/views/user/recover-account/Forgot.vue"),
                    meta: {
                        sidebar: false,
                    },
                },
                // {
                //     path: "/forgot-account",
                //     name: "forgot-account",
                //     component: () =>
                //         import(
                //             "@/views/user/recover-account/ForgotAccount.vue"
                //         ),
                //     meta: {
                //         sidebar: false,
                //     },
                // },
                {
                    path: "/confirm-account-info",
                    name: "confirm-account-info",
                    component: () =>
                        import(
                            "@/views/user/recover-account/ConfirmAccountInfo.vue"
                        ),
                    meta: {
                        sidebar: false,
                    },
                },
                {
                    path: "/reset-email",
                    name: "reset-email",
                    component: () =>
                        import("@/views/user/recover-account/ResetEmail.vue"),
                    meta: {
                        sidebar: false,
                    },
                    props: (route) => ({
                        cpf: route.params.cpf,
                    }),
                },
                {
                    path: "/404",
                    name: "page-error-404",
                    component: () => import("@/views/pages/Error.vue"),
                    meta: {
                        sidebar: false,
                    },
                    props: (route) => ({
                        code: 404,
                    }),
                },
                {
                    path: "/403",
                    name: "page-error-403",
                    component: () => import("@/views/pages/Error.vue"),
                    meta: {
                        sidebar: false,
                    },
                    props: (route) => ({
                        code: 403,
                    }),
                },
                {
                    path: "/privacy-policy",
                    name: "privacy-policy",
                    component: () => import("@/views/pages/PrivacyPolicy.vue"),
                    meta: {
                        sidebar: false,
                    },
                },
                {
                    path: "/terms-and-conditions",
                    name: "terms-and-conditions",
                    component: () =>
                        import("@/views/pages/TermsAndConditions.vue"),
                    meta: {
                        sidebar: false,
                    },
                },
                {
                    path: "/ajuda",
                    name: "faq",
                    component: () => import("@/views/pages/Faq.vue"),
                    meta: {
                        sidebar: false,
                    },
                },
                {
                    path: "/empresas",
                    name: "companies",
                    component: () => import("@/views/custom/Index.vue"),
                    meta: {
                        section: "companies",
                        sidebar: false,
                        header_menu: true
                    },
                },
                {
                    path: "/empresas/termos-de-uso",
                    name: "companies-terms-of-use",
                    component: () => import("@/views/pages/CompaniesTermsOfUse.vue"),
                    meta: {
                        sidebar: false
                    },
                }
            ],
        },
        {
            // =============================================================================
            // ROTAS DO PORTAL (Autenticadas)
            // =============================================================================
            path: "/profile",
            component: () => import("./layouts/painel/Painel.vue"),
            children: [
                {
                    path: "home",
                    name: "profile",
                    component: () => import("@/views/profile/Home.vue"),
                    meta: {
                        auth: {
                            roles: ["candidate", "viewer", "admin"],
                            redirect: { name: "login" },
                        },
                    },
                },
                {
                    path: "curriculum",
                    name: "profile-edit",
                    component: () => import("@/views/profile/Curriculum.vue"),
                    meta: {
                        auth: {
                            roles: ["candidate", "viewer", "admin"],
                            redirect: { name: "login" },
                        },
                    },
                },

                {
                    path: "messages",
                    name: "profile-messages",
                    component: () => import("@/views/profile/Messages.vue"),
                    meta: {
                        auth: {
                            roles: ["candidate", "viewer", "admin"],
                            redirect: { name: "login" },
                        },
                    },
                },
                {
                    path: "configuracoes",
                    name: "profile-configurations",
                    component: () =>
                        import("@/views/profile/Configuration.vue"),
                    meta: {
                        auth: {
                            roles: ["candidate", "viewer", "admin"],
                            redirect: { name: "login" },
                        },
                    },
                },
                {
                    path: "configuracoes/lgpd",
                    name: "profile-configurations-lgpd",
                    component: () => import("@/views/profile/lgpd/Index.vue"),
                    meta: {
                        auth: {
                            roles: ["candidate", "viewer", "admin"],
                            redirect: { name: "login" },
                        },
                    },
                },
                {
                    path: "/profile/minhas-vagas",
                    name: "profile-applications",
                    component: () => import("@/views/profile/Applications.vue"),
                    meta: {
                        auth: {
                            roles: ["candidate", "viewer", "admin"],
                            redirect: { name: "login" },
                        },
                    },
                },
                {
                    path: "admissional",
                    name: "profile-admissional",
                    component: () => import("@/views/profile/Admissional.vue"),
                    alias: ["complementary-data"],
                    meta: {
                        auth: {
                            roles: ["candidate", "viewer", "admin"],
                            redirect: { name: "login" },
                        },
                    },
                    props: (route) => ({
                        candidate_id: route.params.candidate_id,
                        selective_process_id: route.params.selective_process_id,
                    }),
                },
                {
                    path: "/profile/candidatura/:application_id",
                    name: "application-progress",
                    component: () =>
                        import("@/views/profile/ApplicationProgress.vue"),
                    meta: {
                        auth: {
                            roles: ["candidate", "viewer", "admin"],
                            redirect: { name: "login" },
                        },
                    },
                    props: (route) => ({
                        applicationId: route.params.application_id,
                        sjId: route.params.sj_id,
                    }),
                },
                {
                    path: "esocial",
                    name: "profile-esocial",
                    component: () => import("@/views/profile/Esocial.vue"),
                    meta: {
                        auth: {
                            roles: ["candidate", "viewer", "admin"],
                            redirect: { name: "login" },
                        },
                    },
                    props: (route) => ({
                        candidate_id: route.params.candidate_id
                    })
                },
                {
                    path: "/vacancy/enroll/:vacancy_id",
                    name: "vacancy-enroll",
                    component: () => import("@/views/vacancy/Enroll.vue"),
                    meta: {
                        auth: {
                            roles: ["candidate", "viewer", "admin"],
                            redirect: (route) => {
                                window.localStorage.setItem(
                                    "vacancy_id",
                                    route.params.vacancy_id
                                );
                                return {
                                    name: "register1",
                                    params: route.params,
                                };
                            },
                        },
                        sidebar: false,
                    },

                    props: (route) => ({
                        vacancy_id: route.params.vacancy_id,
                    }),
                },
                {
                    path: "/vacancy/apply/:vacancy_id",
                    name: "vacancy-apply",
                    component: () => import("@/views/vacancy/Apply.vue"),
                    meta: {
                        auth: {
                            roles: ["candidate", "viewer", "admin"],
                            redirect: { name: "express-apply" },
                        },
                    },
                    props: (route) => ({
                        vacancyId: route.params.vacancy_id,
                    }),
                },
                {
                    path: "/evaluation/disc/form/:selectiveProcessId?",
                    name: "evaluation-disc-form",
                    component: () =>
                        import("./views/evaluation/disc/form/Main.vue"),
                    meta: {
                        auth: {
                            roles: ["viewer", "admin", "candidate"],
                            redirect: { name: "login" },
                        },
                    },
                    /*
                    props: route => ({
                      service: EvaluationDiscService.build({id: route.params.requestId}),
                      requestId: route.params.requestId
                    }),
                    */
                },
                {
                    path: "/evaluation/disc/results/:requestId/:selectiveProcessId?",
                    name: "evaluation-disc-results",
                    component: () =>
                        import("./views/evaluation/disc/results/Main.vue"),
                    meta: {
                        auth: {
                            roles: ["viewer", "admin", "candidate"],
                            redirect: { name: "page-login" },
                        },
                    },
                    props: (route) => ({
                        //service: EvaluationDiscService.build({id: route.params.requestId}),
                        requestId: route.params.requestId,
                    }),
                },
                {
                    path: "/evaluation/disc/denied/:requestId",
                    name: "evaluation-disc-denied",
                    component: () =>
                        import("./views/evaluation/disc/Denied.vue"),
                    meta: {
                        auth: {
                            roles: ["admin", "candidate"],
                            redirect: { name: "page-login" },
                        },
                    },
                    props: (route) => ({
                        requestId: route.params.requestId,
                    }),
                },
                {
                    path: "/survey/execute/:evaluationId/:selectiveProcessId?",
                    name: "evaluation-survey-execute",
                    component: () =>
                        import("./views/evaluation/survey/execute/Main.vue"),
                    //props: route => ({
                    //  service: EvaluationService.build({id: route.params.requestId})
                    //}),
                    //props: true,
                    meta: {
                        auth: {
                            roles: ["viewer", "admin", "candidate"],
                            redirect: { name: "page-login" },
                        },
                    },
                },
                {
                    path: "/survey/execute/finished",
                    name: "evaluation-survey-finished",
                    component: () =>
                        import(
                            "./views/evaluation/survey/execute/Finished.vue"
                        ),
                    /*
                    props: route => ({
                      service: EvaluationService.build({id: route.params.interviewId})
                    }),
                    //props: true,
                    */
                    meta: {
                        auth: {
                            roles: ["viewer", "admin", "candidate"],
                            redirect: { name: "page-login" },
                        },
                    },
                },

                {
                    path: "/survey/execute/expired",
                    name: "evaluation-survey-expired",
                    component: () =>
                        import("./views/evaluation/survey/execute/Expired.vue"),
                    /*
                    props: route => ({
                      service: EvaluationService.build({id: route.params.interviewId})
                    }),
                    //props: true,
                    */
                    meta: {
                        auth: {
                            roles: ["viewer", "admin", "candidate"],
                            redirect: { name: "page-login" },
                        },
                    },
                },
            ],
        },
        // Redirect to 404 page, if no match found
        {
            path: "*",
            redirect: "/404",
        },
    ],
});

router.beforeEach((to, from, next) => {
    app.loading = true;
    const authRequired = to.matched.some((x) => {
        return x.meta.auth;
    });

    if (authRequired) {
        Vue.auth.load().then(() => {
            const isAuth = Vue.auth.check();
            if (!isAuth) {
                sessionStorage.setItem("redirectPath", to.path);
                next({ name: "login" });
            }
        });
    }

    next();
});

//import { useWebsocket } from "@state/system/websocket";

router.afterEach((to, from) => {

    document.getElementById("app").classList.remove(from.name);
    document.getElementById("app").classList.add(to.name);

    const theme = useTheme();

    if (to.name == "customize") {
        theme.customize = true;
    }

    // if (_.has(to, "query.customize")) {
    //     theme.customize = true;
    // }
    if (_.has(to, "query.slim")) {
        theme.slim = true;
    }

    const isAuth = Vue.auth.check();
    //const websocket = useWebsocket();

    //websocket.listenToPublicChannels();
    //
    //if (isAuth) {
    //    websocket.connectChat();
    //} else {
    //    websocket.leaveChat();
    //}

    app.loading = false;

    //matomo.trackPageView()
});

export default router;
