<template>
  <section v-if="company.config.rodape && company.config.rodape.show" id="rodape" class="d-flex flex-column justify-content-between align-items-center">
    <div class="p-4">
      <img :src="imageRodape()" alt="" height="30" />
      <div class="social-media-container d-flex gap-4 mt-3">
        <a
          v-for="(link, name) in links"
          :key="name"
          class="btn p-3 btn-dt-secondary rounded-circle"
          :href="link"
          target="_blank"
        >
          <img
            :src="imageSocialMedia(name)"
            :alt="name"
            width="25"
            height="25"
            @error="renderPlaceholder($event)"
            v-if="!imageError"
          />
        </a>
      </div>
    </div>

    <div class="w-100 d-flex justify-content-center align-items-center bg-white" :style="'min-height: 70px; border-top: 1px solid #efefef;'">
      <a href="https://selecty.com.br" target="_blank">
        <img :src="imagesList['/resources/assets/images/logo/powered_by_sj.png']" alt="" width="230" />
      </a>
    </div>
  </section>
</template>

<script>
import * as _ from "lodash-es";

import companyMixin from "@state/company";
import images from "@mixins/images";

export default {
  mixins: [companyMixin, images],
  data() {
    return {
      imageError: false,
    };
  },
  computed: {
    links() {
      return _.omitBy(this.company.config.rodape.links, (item) => {
        return _.isNull(item);
      });
    },
  },
  methods: {
    imageRodape() {
      if (this.company.config.sj) {
        return new URL(this.company.config.rodape.logo, import.meta.url).href;
      } else {
        return this.company.config.rodape.logo;
      }
    },
    imageSocialMedia(name) {
      return this.imagesList[
        `/resources/assets/images/social/${name}_grey.png`
      ];
      //return new URL(, import.meta.url).href;
    },
    renderPlaceholder(ev) {
      this.imageError = true;
    },
  },
};
</script>
