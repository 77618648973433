<template>
  <div class="container my-5">
    <div class="row justify-content-center">
      <div class="col-md-12 col-lg-10">
        <h1 class="h4 text-center">{{ $t("terms_of_use.companies.title") }}</h1>
        <h2 class="h5 text-center">{{ $t("terms_of_use.companies.subtitle") }}</h2>
        <div class="my-5" v-if="termsOfUse" v-html="termsOfUse"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    termsOfUse() {
      const termsContent = this.$t("terms_of_use.companies.content", {
        portal_url: this.currentInstanceUrl,
      });

      return termsContent || "";
    },
    currentInstanceUrl() {
      return window.location.origin;
    },
  },
};
</script>